import { useFormContext } from "react-hook-form";
import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { ErrorMsg } from "../../../Component/Comman/Typography";
import { countryCodes } from "../../../utils/countryCodes";

export function SendPointForm() {
  const {
      register,
      formState: { errors, touchedFields, isSubmitting }
  } = useFormContext();

  return (
      <div className="mt-3 row mb-3">
          <div>
              <div className="card">
                  <div className="justify-content-center d-flex flex-column gap-3 card-body">
                      <div className="col">
                          <Form.Label htmlFor="item100" className="form-label required">
                              Mobile Number
                          </Form.Label>
                          <div style={{ display: "flex" }}>
                              <Form.Select aria-label="Select country code" {...register("dialCode")}>
                                  <option disabled={true}>Select country code</option>
                                  {countryCodes.map((code) => (
                                      <option value={code.dial_code}>{code.code} {code.dial_code}</option>
                                  ))}
                              </Form.Select>
                              <Form.Control
                                  placeholder="Enter mobile number"
                                  required
                                  {...register("phoneNumber")}
                                  type="tel"
                                  className="form-control"
                              />
                          </div>
                          {touchedFields.phoneNumber && errors.phoneNumber && <ErrorMsg msg={errors.phoneNumber?.message} />}
                      </div>

                      <div className="col">
                          <Form.Label htmlFor="item100" className="form-label required">
                              Number of Points
                          </Form.Label>
                          <Form.Control
                              placeholder="Enter amount of points to send"
                              required
                              {...register("numberOfPoints")}
                              type="number"
                              className="form-control"
                          />
                          {touchedFields.numberOfPoints && errors.numberOfPoints && (
                              <ErrorMsg msg={errors.numberOfPoints?.message} />
                          )}
                      </div>
                      <div className="col">
                          <Form.Label htmlFor="item100" className="form-label">
                              Note
                          </Form.Label>
                          <Form.Control
                              placeholder="Enter message for recipient"
                              {...register("note")}
                              type="text"
                              className="form-control"
                          />
                          {touchedFields.note && errors.note && <ErrorMsg msg={errors.note?.message} />}
                      </div>
                      <div className="d-flex justify-content-end col">
                          <Button type="submit" disabled={isSubmitting}>
                              {isSubmitting && <Spinner animation="border" variant="light" size="sm" className="me-2" />}
                              Send Points
                          </Button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
}
