import React, { useState, useEffect } from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { selectCurrentUser } from "../../Redux/selectors/auth";
import { AptosAPIInstance } from "../../apis/AptosAPI";
import { useSelector } from "react-redux";


export default function Aptos() {
    const [account, setAccount] = useState(null);
    const [balance, setBalance] = useState(0);
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(true);
    const user = useSelector(selectCurrentUser);

    useEffect(() => {
        if (user?.userId) {
        fetchWalletInfo(user.userId);
        }
    }, [user]);

    const handleCreateWallet = async (user) => {
        try {
        console.log("user ", user?.userId);
        const createWallet = await AptosAPIInstance.createAptosWallet(
            user?.userId
        );
        console.log("createWallet ", createWallet);

        if (createWallet) {
            fetchWalletInfo(user.userId);
        }
        } catch (error) {
        console.error("Error creating wallet:", error);
        }
    };

    const fetchWalletInfo = async (userId) => {
        try {
            const response = await AptosAPIInstance.getWalletInfo(userId);

            if (response.data && response.data.data) {
                const { address, balance } = response.data.data;
                setAccount(address);
                setBalance(balance / 1e8);
            } else {
                console.error("Failed to fetch wallet info");
            }
        } catch (error) {
            console.error("Error fetching wallet information:", error);
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = async (text) => {
        try {
        await navigator.clipboard.writeText(text);
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 2000);
        } catch (error) {
        console.error("Failed to copy text: ", error);
        }
    };

    return (
        <>
        <Link to={process.env.PUBLIC_URL + "/"}>
            <div className="d-flex align-items-center justify-content-start px-5 py-4">
            <div className="d-flex align-items-center">
                <i className="fa fa-chevron-left me-3" />
                <p className="mb-0 ">Back</p>
            </div>
            </div>
        </Link>

        <div className="pricing-main-container m-1 mt-3">
            <h2>Aptos Wallet</h2>
            {loading ? (
            <p>Loading...</p>
            ) : account ? (
            <div className="d-flex flex-column gap-5 justify-content-center align-items-center">
                <h3>Your Aptos Balance: {balance} APT</h3>
                <div>
                <span style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                    Wallet Address:{" "}
                </span>
                <span style={{ fontSize: "1.25rem" }}>{account}</span>
                </div>
                <div
                style={{ width: "400px" }}
                className="referral-earning-card d-flex flex-row gap-5 p-3 justify-content-center"
                onClick={() => copyToClipboard(account)}
                >
                <div className="d-flex flex-row gap-2">
                    <div className="d-flex flex-row align-items-center">
                    <span style={{ fontSize: "1.05rem" }}>
                        {copied ? "Copied!" : "Copy Address"}
                    </span>
                    </div>
                </div>
                </div>
            </div>
            ) : (
            <div
                style={{ width: "400px" }}
                className="referral-earning-card d-flex flex-row gap-5 p-3 justify-content-center"
                onClick={() => handleCreateWallet(user)}
            >
                <div className="d-flex flex-row gap-2">
                <div className="d-flex flex-row align-items-center">
                    <span style={{ fontSize: "1.05rem" }}>Create Wallet</span>
                </div>
                </div>
            </div>
            )}
        </div>
        </>
    );
}