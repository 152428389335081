import React, { useRef, useState } from "react";
import {Modal} from '../../../Modal/Modal';
import {  Button, Form, ProgressBar  } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";

import InputGroup from 'react-bootstrap/InputGroup';
import { socketConstants } from "../../../../apis/SocketApis";
import {generateImageHelper, groupGenerateImageHelper} from "../Helpers/ImageGeneratorHelper.js";
import {getSchedueledImageUrl } from "../../../../utils/images";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../Redux/selectors/auth.js";
import { ChatType } from "../types.js";

//Popup for ImageGenerator in character chatbox

export function ImageGeneratorModalPop({
    setImageGeneratorModalPop, 
    imageGeneratorModalPop,
    setShowRewardAdModal,
    ws,
    characterData,
    customStyles,
    imgGenButtonDisabled, 
    setImgGenButtonDisabled,
    inProgressImage,
    userId, chatType = ChatType.character, groupId = ''}) {
        console.log('chatType', chatType);
        const  imageParams = useRef({prompt: "",
         negative_prompt: "", 
         image_size: 512, 
         gen_type:"txt2img",
         base64FileImage: ""});

        const [generatedImageResult, setGeneratedImageResult] = useState(null);
        const [isAdultImage, setIsAdultImage] = useState(false);
        const [isImg2Img, setIsImg2Img] = useState(false);
        const user = useSelector(selectCurrentUser);
        const dispatch = useDispatch();
        
        const handlePositivePromptChange = (e) => {
            imageParams.current.prompt = e.target.value;
        };

        const handleNegativePromptChange = (e) => {
           imageParams.current.negative_prompt = e.target.value;
        }

        const handleImageSizeChange = (size) => {
            console.log("handleAdultImageChange: ", size);
            imageParams.current.image_size = size;
        }

        const uploadFileBase64 = (e) => {
            //https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
            console.log("uploadFileBase64: ", e.target.files[0]);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
                console.log("reader.result: ", reader.result);
                const strArray = reader.result.split(",");
                imageParams.current.base64FileImage = strArray[1];
            };
            
        }

        const handleGenType = (type) => {
            console.log("handleImageType: ", type);
            imageParams.current.gen_type = type;
            if(type === "img2img"){
                setIsImg2Img(true);
            } else {
                setIsImg2Img(false);
            }
        }

        /* const handleAdultImageChange = (e) => {
            console.log("handleAdultImageChange: ", e.target.value);
            setIsAdultImage(e.target.value);
        } */

        const handleImageGeneratorClick = () => {
            console.log("handleImageGeneratorClick: ", imageParams);
            if(user?.orangePointsCount < 10){
                alert('Orangepoints not enough.');
                return;
            }
            dispatch({
                type: "UPDATE",
                payload: {
                    user: {
                        key: "orangePointsCount",
                        value: user?.orangePointsCount - 10
                    }
                }
            });
            if(chatType === ChatType.character){
                generateImageHelper(imageParams, characterData, ws, userId);
            } else {
                console.log('sending group prompt: characterData: ',characterData)
                groupGenerateImageHelper(imageParams, characterData, groupId, userId, ws);
            }
            setTimeout(() => {
                if(imgGenButtonDisabled) {
                  setImgGenButtonDisabled(false);  
                }
            }, 60000 * 3);
            setImgGenButtonDisabled(true);
            setShowRewardAdModal(true);
        }

        return (
            <Modal
                    setIsOpen={setImageGeneratorModalPop}
                    modalIsOpen={imageGeneratorModalPop}
                    showClose={true}
                    styles={customStyles}
                    body={
                        <div className="px-2 d-flex flex-column gap-3 m-2">
                            <div className="d-flex flex-row justify-content-between">

                            <strong>
                                <span>{` Pay 10 OrangePoints to Generate Image :   `}</span>
                                <Button 
                                    disabled={imgGenButtonDisabled}
                                    onClick={()=>{handleImageGeneratorClick()}}>
                                    Generate Image
                                </Button>
                                <div>
                                <label>Select Image Size</label>
                                </div>
                                <InputGroup>
                                    
                                    <InputGroup.Text>High : 1024 x 1024</InputGroup.Text>
                                    <InputGroup.Radio onClick={()=>{handleImageSizeChange(1024)}} selected name="change_image_size"  aria-label="change image size 1024" />
                                </InputGroup>

                                <InputGroup>
                                    <InputGroup.Text>Low : 64 x 64</InputGroup.Text>
                                    <InputGroup.Radio onClick={()=>{handleImageSizeChange(64)}} name="change_image_size"  aria-label="change image size 64" />
                                </InputGroup>

                                <div>
                                <label>Select Generation Type</label>
                                </div>
                                <InputGroup>
                                    
                                    <InputGroup.Text>Text To Image</InputGroup.Text>
                                    <InputGroup.Radio onClick={()=>{handleGenType("txt2img")}} selected name="change_gen_type"  aria-label="change image size 1024" />
                                </InputGroup>

                                <InputGroup>
                                    <InputGroup.Text>Image To Image</InputGroup.Text>
                                    <InputGroup.Radio onClick={()=>{handleGenType("img2img")}} name="change_gen_type"  aria-label="change image size 64" />
                                </InputGroup>

                                <div>
                                <label>Upload Photo (img2img only)</label>
                                </div>
                                <InputGroup>
                                <input type="file" onChange={uploadFileBase64} disabled={!isImg2Img} id="img2img" name="img2img" accept="image/png, image/jpeg" />
                                </InputGroup>
                            </strong>

                            <span>OP left:<span> {user?.orangePointsCount} </span></span>
                            
                            </div>
                            <label>Positive Prompt</label>
                                <textarea
                                    className="form-control m-0"
                                    placeholder={"Positive Prompt here ..."}
                                    onChange={handlePositivePromptChange} 
                                ></textarea>

                            <label>Negative Prompt</label>
                                <textarea
                                    className="form-control m-0"
                                    placeholder={"Positive Prompt here ..."}
                                    onChange={handleNegativePromptChange} 
                                ></textarea>
                                <ProgressBar now={inProgressImage.percent*100} label={`${inProgressImage.percent*100}%`}  />
                           {inProgressImage.percent > 0 && (
                                <div style={{ width: "150px" }}>
                                    <img
                                        style={{ width: "100%", height: "100%" }}
                                        src={'data:image/png;base64,'+inProgressImage.current_image}
                                        //inProgressImage.current_image
                                        alt={generatedImageResult}
                                    />
                                </div>
                            )}
                        </div>
                    }
            />
        );
}
    

//Popup for selecting open source model
export function SelectSpicyAiModal({
    setSelectSpicyAiPopup, 
    selectSpicyAiPopup, 
    setSpicyAiModels, 
    spicyAiModels,
    ws, 
    customStyles,
    selectedSpicyAiModel,
    setSelectedSpicyAiModel}) {

    const handleSelectSpicyAi = (e) => {
        ws.send(JSON.stringify({
            type: socketConstants.CLIENT.CUSTOM_AI.UPDATE_CLIENT_AI_MODEL,
            aiModelInfo: e.target.value
        }));
        setSelectedSpicyAiModel(e.target.value);
        setSelectSpicyAiPopup(false);
    }

    const handleRefresh = (e) => {
        
        ws.send(JSON.stringify({
            type: socketConstants.CLIENT.CUSTOM_AI.GET_ALL_AVAILABLE_REPLIERS,
            data: e.target.value
        }));
        
    }
   
    return (
        <Modal
                setIsOpen={setSelectSpicyAiPopup}
                modalIsOpen={selectSpicyAiPopup}
                showClose={true}
                styles={customStyles}
                body={
                    <div className="px-2 d-flex flex-column gap-3">
                        <strong>
                            <span>{`Select a specific Ai Model for Spicy Mode (spicy off is ChatGpt3.5 turbo):` + spicyAiModels?.length}</span>
                            <Button onClick={handleRefresh}>Refresh</Button>
                            
                            {spicyAiModels?.length > 0 &&
                                     spicyAiModels.map((aiModelMiner, i) => { 
                                        return (<Form.Check // prettier-ignore
                                        type={"radio"}
                                        id={`default-radio-${i}`}
                                        label={aiModelMiner?.aiModelInfo +": username: "+ aiModelMiner?.username}
                                        name="radioGroup"
                                        value={aiModelMiner?.aiModelInfo}
                                        onChange={handleSelectSpicyAi}
                                        checked={aiModelMiner?.aiModelInfo === selectedSpicyAiModel}
                                      />);
                                     })
                            }

                        </strong>
                       
                    </div>
                }
        />
    );
}

//Popup for translate
export function AutoTranslateModal({setTranslateModal, 
    translateModal, 
    autoTranslate, 
    handleToggleTranslate, 
    customStyles}) {
    return (
        <Modal
                setIsOpen={setTranslateModal}
                modalIsOpen={translateModal}
                showClose={true}
                styles={customStyles}
                body={
                    <div className="px-2 d-flex flex-column gap-3">
                        <strong>
                            <Form.Label>Do you want to auto translate to your language settings?</Form.Label>
                        </strong>
                       <Form.Switch
                            label={`Translate ${autoTranslate ? "On" : "Off"}`}
                            defaultChecked={autoTranslate}
                            onChange={handleToggleTranslate}
                            checked={autoTranslate}
                        />
                    </div>
                }
                />
    );
}
