import React from "react";
import { FiChevronRight } from "react-icons/fi";
import Avatar from "../../Component/Comman/Avatar";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../Redux/selectors/auth";
import aptosLogo from '../../assets/images/logo/aptos-apt-logo.png';

export default function PointsList({ points }) {

  const navigate = useNavigate();

  const user = useSelector(selectCurrentUser);

  const gemPoint = {
      pointRewardName: "Gems",
      totalAmount: user?.gemsCount ?? 0,
      symbol: 'G'
  };
  const orangePoint = {
      pointRewardName: "Orangepoints",
      totalAmount: user?.orangePointsCount ?? 0,
      symbol: 'OP'
  };
 
  const handlePointClick = (storeId) => {
      navigate(`/store/${storeId}`);
  };

  const handleAptosClick = () => {
    navigate(`/aptos-wallet`);
  };

  return (
      <div className="d-flex flex-row gap-4 flex-wrap">
          <PointV2 point={gemPoint}/>
          <PointV2 point={orangePoint}/>
          {points.map((point) => (
              <PointV2 point={point} handlePointClick={handlePointClick} />
          ))}
          <AptosBtn handleAptosClick={handleAptosClick} />
      </div>
  );
}

const AptosBtn = ({ handleAptosClick = () => {} }) => {
    return (
        <div
            style={{ width: "400px" }}
            className="referral-earning-card d-flex flex-row gap-5 p-3 justify-content-between"
            onClick={handleAptosClick}
        >
            <div className="d-flex flex-row gap-2">
                <img className="aptosLogo" src={aptosLogo} alt="" style={{ width: "50px" }}/>
                <div className="d-flex flex-row align-items-center">
                    <span style={{ fontSize: "1.05rem" }}>Aptos</span>
                </div>
            </div>
        </div>
    );
};

const Point = ({point, handlePointClick = () => {}}) => {
    return (
        <div key={point._id} onClick={() => handlePointClick(point?.pointTokenID?.merchantStoreID)}>
            <Card className="p-3 mb-2 clickable-card clickable-list-item">
                <div className="d-flex align-items-center pointer">
                    <Avatar name={point?.pointRewardName} avatarUrl={point?.pointImageUrl} className="xl pointer" />
                    <div className="flex-fill ms-3 text-truncate">
                        <h6 className="justify-content-between mb-0 d-flex clickable-list-text">{point?.pointRewardName}</h6>

                        <div className="d-flex align-items-center mt-2">
                            <small className="msg-time pointer clickable-list-text">{point?.totalAmount}</small>
                        </div>
                    </div>
                    <div>
                        <FiChevronRight size={24} className="clickable-list-text" />
                    </div>
                </div>
            </Card>
        </div>
    );
};

const PointV2 = ({ point, handlePointClick = () => {} }) => {
    return (
        <div
            style={{ width: "400px" }}
            onClick={() => handlePointClick(point?.pointTokenID?.merchantStoreID)}
            className="referral-earning-card d-flex flex-row p-3 justify-content-between"
        >
            <div className="d-flex flex-row gap-2">
                <Avatar name={point?.pointRewardName} avatarUrl={point?.pointImageUrl} className="referral-avatar" />
                <div className="d-flex flex-column gap-2 align-items-start">
                    <span style={{ fontSize: "1.05rem" }}>{point?.pointRewardName}</span>
                    <span className="opacity-75">{point?.symbol ?? point?.pointRewardName?.slice(2)}</span>
                </div>
            </div>
            <div className="d-flex flex-column align-items-end gap-2">
                <span style={{ fontSize: "1.05rem" }}>
                    {point?.totalAmount} {point?.symbol ?? point?.pointRewardName?.slice(2)}
                </span>
                <span className="opacity-75">$ {point?.totalAmount}</span>
            </div>
        </div>
    );
};
