import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ChatTab from "./ChatTab";
import { Button, Spinner } from "react-bootstrap";
import EmptyPage from "./EmptyChatTab";
import { ClientCharAPIInstance } from "../../../apis/ClientCharApi";

import { getAiCharacterImageUrl } from "../../../utils/images";
import { removeChatPrefix } from "../../../utils/customCharacter";
import { useNavigate } from "react-router";

export default function ClientCharTab({ setSelectedId, selectedId, selectedType, status, chatsDrawerOpen, toggleDrawer, selectedUserId = "" }) {
    const dispatch = useDispatch();
    const [clientPairs, setClientPairs] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchClientChats = useCallback(async () => {
        setLoading(true);

        const res = await ClientCharAPIInstance.getAllClientsChatList();

        if (res?.data?.data?.myClientsList) {
            const aiCustomCharacters = res?.data?.data?.myClientsList;

            const sorted = aiCustomCharacters.sort((a, b) => b.createdAt - a.createdAt);
            //alert(JSON.stringify(sorted));
            setClientPairs(sorted); //TODO: change to dispatch

            if (sorted.length > 0 && !selectedId) {
                setSelectedId(sorted[0], "client");
            }
        }

        setLoading(false);
    }, [dispatch]);

    useEffect(() => {
        if (selectedType === "client" || !selectedType) {
            fetchClientChats();
        }
    }, [selectedType]);

    const handleSelectChat = (character) => {
        if(chatsDrawerOpen){
            toggleDrawer();
        }
        setSelectedId(character, "client");
    }

    const handleCreateCharacterClick = () => {
        navigate('/create-character');
    }

    const getCharacterAvatar = (character) => {
        if(character?.avatarUrls && character?.avatarUrls?.[0]){
            return getAiCharacterImageUrl(character?.avatarUrls[0]);
        } else if (character?.avatarUrl){
            return getAiCharacterImageUrl(character?.avatarUrl);
        } else return null
    }

    return (
        <div className="d-flex flex-column flex-grow-1">
            <Button
                className="rounded align-items-center justify-content-center d-flex m-3"
                variant="light"
                onClick={handleCreateCharacterClick}
            >
                <i className="bi bi-plus mb-1"></i>
                <p className="mb-0 ms-1">Create Character</p>
            </Button>

            {loading ? (
                <div className="justify-content-center align-items-center d-flex my-4">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : clientPairs.length === 0 ? (
                <EmptyPage />
            ) : (
                <>
                    <ul
                        className="list-unstyled list-group list-group-custom list-group-flush px-2 gap-2 pt-2"
                        style={{ maxHeight: "85vh" }}
                    >
                        {clientPairs.length > 0 &&
                            clientPairs.map((pair, i) => {
                                return (
                                    <ChatTab
                                        key={`${pair?._id}-${pair?.clientUser?._id}`}
                                        id={pair?._id}
                                        name={ pair?.clientUser?.username +" & "+ pair?.characterName }
                                        avatarUrl={
                                            // pair?.avatarUrl !== "" &&
                                            // getAiCharacterImageUrl(pair?.avatarUrl)
                                            getCharacterAvatar(pair)
                                        }
                                        // timestamp={character.createdAt}
                                        message={removeChatPrefix(pair?.lastMessage?.messageContent?.encrypted_text)}
                                        onClick={() => handleSelectChat(pair)}
                                        selectedId={selectedId}
                                        status={status}
                                        selectedUserId={selectedUserId}
                                        userId={pair?.lastMessage?.userId}
                                    />
                                );
                            })}
                    </ul>
                </>
            )}
        </div>
    );
}
