import React, { useEffect, useState } from 'react'
import { Tab, Nav } from "react-bootstrap";
import CouponsList from './CouponsList';
import { PointRewardApiInstance } from '../../apis/PointRewardApi';
import { readCookie } from '../../utils/cookie';
import PointsList from './PointsList';
import Earnings from './Earnings';

export default function CouponWallet() {
  const [points, setpoints] = useState([]);
  const token = readCookie('token');  

  useEffect(() => {
    PointRewardApiInstance.getAllMyPoints(token)
    .then(res => {
        setpoints(res?.data?.data?.pointWallets ?? []);
    })
    .catch(console.error);
  }, [token]);

  return (
    <div className="container-xxl">
    <div className="row g-3 mb-3">
        <div className="col-md-12">
            <Tab.Container defaultActiveKey="points">
                <div className="card-header pb-3 d-flex justify-content-between bg-transparent align-items-center flex-wrap">
                    <Nav className="nav nav-pills rounded d-inline-flex mt-2 mt-md-0 rounded-0" role="tablist">
                        <Nav.Item className="nav-item">
                            <Nav.Link
                                eventKey="points"
                                className="nav-link "
                                data-bs-toggle="tab"
                                role="tab"
                                aria-selected="false"
                            >
                                Points
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item">
                            <Nav.Link
                                eventKey="coupons"
                                className="nav-link"
                                data-bs-toggle="tab"
                                role="tab"
                                aria-selected="true"
                            >
                                Coupons
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item">
                            <Nav.Link
                                eventKey="earnings"
                                className="nav-link"
                                data-bs-toggle="tab"
                                role="tab"
                                aria-selected="true"
                            >
                                Earnings
                            </Nav.Link>
                        </Nav.Item>
                        
                    </Nav>
                </div>
                <div className="card-body">
                    <Tab.Content className="tab-content">
                        
                        <Tab.Pane className="tab-pane fade" id="points" eventKey="points">
                            <PointsList points={points}/>
                        </Tab.Pane>
                        <Tab.Pane className="tab-pane fade show" id="coupons" eventKey="coupons">
                            <CouponsList/>
                        </Tab.Pane>
                        <Tab.Pane className="tab-pane fade show" id="earnings" eventKey="earnings">
                            <Earnings/>
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    </div>
</div>
  )
}
