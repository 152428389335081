/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Tab, Nav } from "react-bootstrap";
import Markets from "./components/Markets";
import PageTitle from "../../Component/Comman/PageTitle";
import PendingOrders from "./components/PendingOrders";
import TradeHistory from "./components/TradeHistory";
import useOrders from "../../Hooks/Orders/useOrders";

function PointExchange() {
    const orders = useOrders({ type: "point", status: "pending", me: "false" });
    const myOrders = useOrders({ type: "point", status: "pending", me: "true" });
    const tradeHistory = useOrders({ type: "point", status: "pending", me: "true" });

    return (
        <div className="px-lg-4 px-md-4">
            <PageTitle pagetitle="Points Market" />

            <div className="container-xxl">
                <div className="row g-3 mb-3">
                    <div className="col-md-12">
                        <Tab.Container defaultActiveKey="markets">
                            <div className="card-header py-3 d-flex justify-content-between bg-transparent align-items-center flex-wrap">
                                <Nav className="nav nav-pills rounded d-inline-flex mt-2 mt-md-0 rounded-0" role="tablist">
                                    <Nav.Item className="nav-item">
                                        <Nav.Link
                                            eventKey="markets"
                                            className="nav-link"
                                            data-bs-toggle="tab"
                                            href="#markets"
                                            role="tab"
                                            aria-selected="false"
                                        >
                                            Markets
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item">
                                        <Nav.Link
                                            eventKey="pending-orders"
                                            className="nav-link "
                                            data-bs-toggle="tab"
                                            href="#pending-orders"
                                            role="tab"
                                            aria-selected="true"
                                        >
                                            Pending Orders
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item">
                                        <Nav.Link
                                            eventKey="trade-history"
                                            className="nav-link "
                                            data-bs-toggle="tab"
                                            href="#trade-history"
                                            role="tab"
                                            aria-selected="true"
                                        >
                                            Trade History
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="card-body">
                                <Tab.Content className="tab-content">
                                    <Tab.Pane className="tab-pane fade" id="markets" eventKey="markets">
                                        <Markets orders={orders} />
                                    </Tab.Pane>
                                    <Tab.Pane className="tab-pane fade  show" id="pending-orders" eventKey="pending-orders">
                                        <PendingOrders orders={myOrders} />
                                    </Tab.Pane>
                                    <Tab.Pane className="tab-pane fade  show" id="trade-history" eventKey="trade-history">
                                        <TradeHistory orders={tradeHistory} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PointExchange;
