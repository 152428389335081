import React, { useEffect, useState } from "react";
import PageTitle from "../../../Component/Comman/PageTitle";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Spinner, Table } from "react-bootstrap";
import { ErrorMsg } from "../../../Component/Comman/Typography";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { PointRewardApiInstance } from "../../../apis/PointRewardApi";
import { CustomToast, ToastStatus } from "../../../Component/Comman/CustomToast";
import { formatDate } from "../../../utils/formatter/dateFormatter";
import { AxiosError } from "axios";
import PointsHistory from "./PointsHistory";
import { SendPointForm } from "./SendPointForm";

const pointsSchema = Yup.object({
    dialCode: Yup.string()
    .matches(/^\+\d{1,4}$/, "Invalid dial code")
    .label("Dial code")
    .required(),
    phoneNumber: Yup.string()
        .matches(/^\d{1,14}$/, "Invalid phone number format")
        .label("Mobile Number")
        .required(),
    numberOfPoints: Yup.number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .min(1, "Must be atleast 1")
        .max(100, "Must not exceed 100 points")
        .label("Amount of Points")
        .required(),
    note: Yup.string().label("Note")
});

export default function SendPoints() {
    const merchant = useSelector((state) => {
        return state?.merchant?.currentUserMerchant;
    });
    const [toast, setToast] = useState({
        show: false,
        message: "",
        status: ""
    });

    const methods = useForm({ resolver: yupResolver(pointsSchema) });
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sendPointsHistory, setSendPointsHistory] = useState([]);

    const fetchHistory = async ({ limit, skip }) => {
        setLoading(true);
        try {
            const response = await PointRewardApiInstance.getP2PSentPoints({
                limit,
                skip
            });

            if (response.status === 200) {
                setSendPointsHistory(response.data.data.points);
                setTotalRows(response.data.data.total);
            }
        } catch (e) {
            console.error(e?.message ?? "Error fetching points");
        }

        setLoading(false);
    };

    const handlePageChange = (page) => {
        fetchHistory({ limit: perPage, skip: (page - 1) * perPage });
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);

        fetchHistory({ limit: newPerPage });
    };

    useEffect(() => {
        fetchHistory({ limit: perPage });
    }, []);

    async function onSubmit(data) {
        try {
            const {dialCode, ...rest} = data;
            rest.phoneNumber = `${dialCode}${rest.phoneNumber}`;
            const body = { ...rest, merchantUUID: merchant._id };
            const response = await PointRewardApiInstance.sendPoints(body);

            if (response.status === 201) {
                methods.reset();
                fetchHistory({ limit: perPage });
                setToast({
                    show: true,
                    message: "Successfully sent points!",
                    status: ToastStatus.SUCCESS
                });
            }
        } catch (error) {
            let msg = "An error has occurred";

            if (error instanceof AxiosError) {
                msg = error.response.data?.message ?? error.response.data;
            }

            setToast({
                show: true,
                message: msg,
                status: ToastStatus.ERROR
            });
        }
    }

    return (
        <div>
            <div className="container-xxl px-4">
                <PageTitle pagetitle="Send Points" goBackLink="/my-store" />
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <SendPointForm />
                    </form>
                </FormProvider>
                <PointsHistory
                    loading={loading}
                    totalRows={totalRows}
                    sendPointsHistory={sendPointsHistory}
                    handlePerRowsChange={handlePerRowsChange}
                    handlePageChange={handlePageChange}
                />
                <CustomToast
                    status={toast.status}
                    show={toast.show}
                    message={toast.message}
                    onClose={() => setToast((prev) => ({ ...prev, show: false }))}
                />
            </div>
        </div>
    );
}
