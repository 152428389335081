import React, { memo } from "react";
import { Badge, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const dateOptions = {
    month: "long",
    day: "numeric",
    year: "numeric"
};

function OrderItem({ to, order }) {
    return (
        <Card className="p-3 mb-2">
            <div className="d-flex flex-row justify-content-between">
                <h5>{order?.pairKey}</h5>
                <div>
                    {order?.side === "ask" ? (
                        <Badge pill className="px-3 py-2" bg="danger">
                            SELL
                        </Badge>
                    ) : (
                        <Badge pill className="px-3 py-2" bg="success">
                            BUY
                        </Badge>
                    )}
                </div>
            </div>
            <h6>$ {order?.price}</h6>
            <span>Maker: {order?.makerUserId?.username}</span>
            <span>Amount: {order?.amount}</span>
            <span>Status: {String(order?.status)}</span>
            <span>
                Date Created: {new Date(parseInt(order?.timestamp, 10) * 1000).toLocaleDateString("en-US", dateOptions)}
            </span>
            <div className="align-self-end">
                {to && (
                    <Link to={to}>
                        <Button variant="warning text-white px-3">Take Order</Button>
                    </Link>
                )}
            </div>
        </Card>
    );
}

export default memo(OrderItem);
