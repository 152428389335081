import * as Yup from "yup";

export const aiCharacterSchema = Yup.object({
  avatarUrl: Yup.array(Yup.mixed().optional()).optional(),
  avatarUrls: Yup.array(Yup.mixed().optional()).optional(),
  coverUrl: Yup.mixed().optional(),
  characterName: Yup.string().label("Character Name").required(),
  description: Yup.string().label("Description").required(),
  firstMessage: Yup.string().label("First Message").required(),
  personalitySummary: Yup.string().label("Personality Summary").required(),
  physicalAppearance: Yup.string().label("Physical Appearance").required(),
  gender: Yup.string().label("Gender").required(),
  age: Yup.string().label("Age").required(),
  likes: Yup.string().label("Likes").required(),
  scenario: Yup.string().label("Scenario"),
  dialogueSample: Yup.string().label("Dialogue Sample"),
  makeCharacterPublic: Yup.boolean().label("Make Public Character"),
  monetize: Yup.boolean().label("Monetize"),
  price:Yup.string().label("price"),
  currency:Yup.string().label("currency"),
});

export const AIFormViewTypes = {
  CHARACTER: 'CHARACTER',
  SCHEDUELED_MESSAGES: 'SCHEDUELED_MESSAGES'
};

export const defaultSchedueledMessageState = {
  messageText: "",
  timezone: "Europe/Andorra",
  time: "0:00",
  frequency: "daily",
  file: null,
};