import React from "react";
import OrderItem from "./OrderItem";

function PendingOrders({ orders }) {
    return !!orders ? (
        <div>
            {orders?.map((order, i) => (
                <OrderItem key={`pending-order-item-${i}`} to="" order={order} />
            ))}
        </div>
    ) : (
        <></>
    );
}

export default PendingOrders;
