import React, { useCallback, useEffect, useState } from "react";
import { getCouponImageUrl } from "../../utils/images";
import { MerchantApiInstance } from "../../apis/MerchantsAPI";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import ClickableListItem from "../../Component/Explore/ClickableListItem";
import { PointRewardApiInstance } from "../../apis/PointRewardApi";
import { readCookie } from "../../utils/cookie";

export default function CouponsList() {
    const [coupons, setCoupons] = useState([]);
    const navigate = useNavigate();
    const loggedInUserID = useSelector((state) => state?.AuthReducer?.user?.userId);
    const token = readCookie('token');

    const fetchAllCoupons = useCallback(async () => {
      try {
          const result = await PointRewardApiInstance.getPurchasedCoupons(token);
          setCoupons(result.data.data.purchasedCoupons.filter(coupon => coupon?.couponID ? true : false));
      } catch (error) {
          console.log(error);
      }
  }, [token]);

    useEffect(() => {
        fetchAllCoupons();
    }, [fetchAllCoupons]);

    const handleStoreNameClick = (coupon) => {
        if (coupon?.merchantStoreID?.userId !== loggedInUserID) {
            navigate(`/store/${coupon?.merchantStoreID._id}`, {
                state: {
                    merchantID: coupon?.merchantStoreID._id,
                    from: "explore"
                }
            });
        } else {
            navigate(`/my-store`);
        }
    };

    const handleCouponClick = (coupon) => {
        navigate(`/coupon-scan/`, {state: coupon});
    }

    return (
        <div>
            <ul className="list-unstyled list-group list-group-custom list-group-flush mb-0">
                {coupons.length > 0 &&
                    coupons.map((coupon, i) => {
                        return (
                            <ClickableListItem
                                key={coupon?.couponID?._id}
                                onClick={() => handleCouponClick(coupon)}
                                onSubtitleClick={() => handleStoreNameClick(coupon)}
                                id={coupon?.couponID?._id}
                                title={coupon?.couponID?.couponName}
                                creatorName={coupon?.merchantStoreID?.storeName ?? "-"}
                                itemUrl={
                                    coupon?.couponID?.couponPhoto &&
                                    (coupon?.couponID?.couponPhoto.includes("http")
                                        ? coupon?.couponID?.couponPhoto
                                        : getCouponImageUrl(coupon?.couponID?.couponPhoto))
                                }
                            />
                        );
                    })}
            </ul>
        </div>
    );
}
