import React from "react";
import OrderItem from "./OrderItem";

function Markets({ orders }) {
    return !!orders ? (
        <div>
            {orders?.map((order, i) => (
                <OrderItem key={`order-item-${i}`} to={`/point-exchange/order/${order._id}`} order={order} />
            ))}
        </div>
    ) : (
        <></>
    );
}

export default Markets;
