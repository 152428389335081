import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import AIChatTab from "../../Component/App/Chat/AIChatTab";
import ClientCharTab from "../../Component/App/Chat/ClientCharTab";
import UserChatTab from "../../Component/App/Chat/UserChatTab";
import Avatar from "../../Component/Comman/Avatar";
import { setAllChats } from "../../Redux/Reducers/ChatReducer";
import { authAPIInstance } from "../../apis/AuthAPI";
import { ChatApiInstance } from "../../apis/ChatAPI";
import { NewSocketService } from "../../apis/SocketApis/NewSocketService";
import { socketCheckerInterval } from "../../apis/SocketApis/SocketChecker";
import { getUserImageUrl } from "../../utils/images";
import EVENTS from "../../utils/socketEvents";
import ChatBoxSwitcher from "./ChatBoxSwitcher";

dayjs.extend(relativeTime);

const chatTabs = [
    { tabTitle: "Chars", eventKey: "ai", href: "ai-chats" },
    { tabTitle: "Users", eventKey: "user", href: "user-chats" },
    { tabTitle: "Clients", eventKey: "client", href: "client-chats" }
];

function Chat({ user }) {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const {sendMessage, lastMessage, readyState, getWebSocket} = NewSocketService("Chat.js");
    const [userInfo, setUserInfo] = useState({});

    let ws = getWebSocket();
        if (ws) {
            socketCheckerInterval(getWebSocket(), dispatch, "Chat.js");
            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data?.type === EVENTS.SERVER.CUSTOM_AI.GET) {
                    if (data?.data?.totalMiners > 0) {
                        setStatus("online");
                    } else {
                        setStatus("offline");
                    }
                }
            }
        }
        
    const [status, setStatus] = useState("offline");
    const [loading, setLoading] = useState(false);
    const [activeChat, setActiveChat] = useState(null);
    const [chatsDrawerOpen, setChatsDrawerOpen] = useState(false);

    const [currentGroupChat, setCurrentGroupChat] = useState();

    // const isMerchant = searchParams.get("isMerchant") === "true" ? true : false;

    const selectedId = searchParams.get("id");
    const selectedType = searchParams.get("type");
    const isGroup = searchParams.get("isGroup");
    const selectedUserId = searchParams.get("userId");

    const setSelectedId = (chat, type) => {
        console.log('setSelectedId', chat, type)
        if (type === "ai") {
            //clean up here
            setSearchParams({ type: "ai", id: chat._id });
        } else if (type === "user") {
            setSearchParams({ type: "user", id: chat.recipient_id });
            setActiveChat(chat);
        } else if (type === "client") {
            setSearchParams({ type: "client", id: chat._id, userId: chat?.clientUser?._id  });
            //setActiveChat(chat);
        } else if (type === "group") {
            setSearchParams({ type: "ai", isGroup: true, id: chat._id });
            setCurrentGroupChat(chat);
        }
    };

    /* useEffect(() => {
        const timeOutFn = setInterval(() => getTotalMiners(), 5000);
        getTotalMiners();

        return () => {
            clearInterval(timeOutFn);
        };
    }, [sendMessage, dispatch]); */

    useEffect(() => {
        if (selectedType === "user") {
            fetchUserChats();
        }
    }, [selectedType]);

    function getTotalMiners() {
        sendMessage(JSON.stringify({ type: EVENTS.CLIENT.CUSTOM_AI.GET }));
    }

    const fetchUserChats = useCallback(async () => {
        setLoading(true);

        const res = await ChatApiInstance.getAllChatList();

        if (res?.data?.data) {
            const chatsData = res.data.data;

            const sorted = chatsData.sort((a, b) => b.timeStamp - a.timeStamp);
            // temporary way to fetch merchant data for user chat
            const newData = [];

            for (const chat of sorted) {
                if(chat?.recipient_id) {
                    newData.push(chat);
                }
            }

            console.log("selectedId: ", selectedId);
            if (selectedId && newData.findIndex((chat) => chat.recipient_id === selectedId) === -1) {
                // for new chat with store
                const response = await authAPIInstance.getUserPublicProfile(selectedId, null);
                //TODO: if response is null, fetch from user api (in cases where owner of chat is just a user)
                
                const otherUser = response?.data?.data;
                if(otherUser){
                    const newChatData = {
                        chatId: null,
                        chatName: otherUser?.username,
                        isRead: false,
                        messageContent: "",
                        recipient_avatar: otherUser?.avatar_url ?? "",
                        recipient_id: selectedId,
                        timeStamp: Date.now() / 1000,
                        userUrlAddress: otherUser?.username
                    };

                    dispatch(
                        setAllChats({
                            data: [newChatData, ...newData]
                        })
                    );

                    setActiveChat(newChatData);
                }
            } else {
                // sets the first chat as default if there is no selectedId
                dispatchDefaultChat(newData, dispatch);
            }
        }

        setLoading(false);
    }, [dispatch]);

    const dispatchDefaultChat = (newData, dispatch) => {
        dispatch(
            setAllChats({
                data: newData
            })
        );

        if (newData.length > 0) {
            setSelectedId(newData[0], "user");
            setActiveChat(newData[0]);
        }
    };


    const toggleDrawer = () => {
        setChatsDrawerOpen(prev => !prev);
    };

    return (
        <div className="card-chat-container">
            <div
                id="tabboxes"
                className={`card card-chat border-end border-0 w380 rounded-0 d-flex ${chatsDrawerOpen ? "open" : ""}`}
            >
                <Tab.Container
                    activeKey={selectedType}
                    defaultActiveKey={"user"}
                    onSelect={(key) => setSearchParams({ type: key })}
                >
                    <Row>
                        <Col sm={12}>
                            <div className="px-3 py-3 ">
                                <div className="input-group justify-content-between align-items-center">
                                    <h1 className="h4 mb-0">Chats</h1>
                                    <a className=" py-2 d-block d-lg-none chatlist-toggle" href="#!" onClick={toggleDrawer}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                    <div>
                                        <Avatar
                                            name={user.username}
                                            avatarUrl={
                                                user.avatar_url !== "" &&
                                                (user.avatar_url.includes("http")
                                                    ? user.avatar_url
                                                    : getUserImageUrl(user.avatar_url))
                                            }
                                            className="br-50"
                                        />
                                    </div>
                                </div>
                                {/* <div className="mt-3 d-flex">
                                    <input type="text" className="form-control w-100" placeholder="Search..." />
                                </div> */}
                                <Nav className="nav nav-pills justify-content-between text-center mt-3" role="tablist">
                                    {chatTabs.map((tb) => (
                                        <Nav.Link
                                            key={tb.eventKey}
                                            className="flex-fill rounded border-0 nav-link"
                                            eventKey={tb.eventKey}
                                            href={`#${tb.href}`}
                                        >
                                            {tb.tabTitle}
                                        </Nav.Link>
                                    ))}
                                </Nav>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <Tab.Content className="tab-content border-top">
                                <Tab.Pane className="" eventKey="ai" id="chat-groups" role="tabpanel">
                                    <AIChatTab
                                        selectedId={selectedId}
                                        setSelectedId={setSelectedId}
                                        selectedType={selectedType}
                                        status={status}
                                        toggleDrawer={toggleDrawer}
                                        chatsDrawerOpen={chatsDrawerOpen}
                                        currentGroupChat={currentGroupChat}
                                        isGroup={isGroup}
                                    />
                                </Tab.Pane>
                                <Tab.Pane className="" eventKey="user" id="chat-recent" role="tabpanel">
                                    <UserChatTab
                                        loading={loading}
                                        setSelectedId={setSelectedId}
                                        selectedId={selectedId}
                                        selectedType={selectedType}
                                        toggleDrawer={toggleDrawer}
                                        chatsDrawerOpen={chatsDrawerOpen}
                                    />
                                </Tab.Pane>
                                <Tab.Pane className="" eventKey="client" id="chat-groups" role="tabpanel">
                                    <ClientCharTab
                                        selectedId={selectedId}
                                        setSelectedId={setSelectedId}
                                        selectedType={selectedType}
                                        status={status}
                                        toggleDrawer={toggleDrawer}
                                        chatsDrawerOpen={chatsDrawerOpen}
                                        selectedUserId={selectedUserId}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
            <div className="card card-chat-body border-0 ">
                {selectedId ? (
                    <>
                        <div id="chat-box" className={`${chatsDrawerOpen ? "overlay" : ""}`} onClick={toggleDrawer} />
                        <ChatBoxSwitcher selectedType={selectedType} isGroup={isGroup}
                          user={user} selectedId={selectedId} toggleDrawer={toggleDrawer}
                          status={status} selectedUserId={selectedUserId}
                          currentGroupChat={currentGroupChat} setCurrentGroupChat={setCurrentGroupChat}
                          activeChat={activeChat}
                          loading={loading}
                        />
                    </>
                ) : (
                    <div></div>
                )}
            </div>
        </div>
    );
}
export default Chat;
