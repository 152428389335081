import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CreatorProfileSchema } from "../schema/CreatorProfileSchema";
import { getUserImageUrl } from "../../../utils/images";
import { ErrorMsg } from "../../../Component/Comman/Typography";
import { IoTrash } from "react-icons/io5";
import { BiSolidImageAdd } from "react-icons/bi";
import { theme } from "../../../utils/config";
import "../index.css";
import { Modal } from "../../../Component/Modal/Modal";
import CreateService from "./components/CreateServiceForm";
import { selectCurrentUser } from "../../../Redux/selectors/auth";
// import { ServiceCard } from "./components/ServiceCard";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        minWidth: "25%",
        backgroundColor: "rgba(0,0,0,0.6)",
        borderColor: "transparent",
        borderRadius: "12px",
        height: "80vh",
        overflowY: "auto"
    },
    overlay: {
        backgroundColor: "rgba(4, 4, 35, .5)",
        backdropFilter: "blur(3px)"
    }
};

const CreatorProfileForm = ({ buttonContent, onSubmit, handleDeleteCoupon, isSubmitting = false, isDeleting = false }) => {
    const formikCtx = useRef();
    const userImageInput = useRef(null);
    const user = useSelector(selectCurrentUser)
    const { t } = useTranslation("add_coupon");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [showSocialMediaModal, setShowSocialMediaModal] = useState(false);
    const [userImage, setUserImage] = useState(null);

    useEffect(() => {
        if (user?.avatar_url) {
            setUserImage(user?.avatar_url);
        }
    }, [user?.avatar_url]);


    const handleSubmitCoupon = async () => {
        console.log("socialMediaLinks:", formikCtx.current.values.socialMediaLinks);
        await onSubmit({
            body: {
                username: formikCtx.current.values.creatorName,
                description: formikCtx.current.values.creatorDescription,
                socialLinks: JSON.stringify(formikCtx.current.values.socialMediaLinks),
                imageRemoved: user?.avatar_url && !userImage
            },
            user_photo: userImage?.file
        });
    };

    const handleImagePicker = () => {
        userImageInput?.current?.click();
    };

    const getInitialValues = () => {
        return {
            creatorName: user?.username ?? '',
            creatorDescription: user?.description ?? '',
            socialMediaLinks: user?.socialLinks ? user.socialLinks : []
        };
    };

    const removeImage = () => {
        setUserImage(null);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setUserImage({ file, url: reader.result });
            };
            reader.readAsDataURL(file);
        }
    };

    const getImageUrl = () => {
        if(typeof userImage === "object"){
            return userImage.url
        }
        return getUserImageUrl(userImage);
    };

    const addLink = () => {
        formikCtx.current.setValues({
            ...formikCtx.current.values,
            socialMediaLinks: [
                ...formikCtx.current.values.socialMediaLinks,
                { link: "", label: "" }
            ]
        });
    };
    

    const handleLinkChange = (value, i, key) => {
        const newLinks = [...formikCtx.current.values.socialMediaLinks];
        newLinks[i][key] = value;
        formikCtx.current.setValues({
            ...formikCtx.current.values,
            socialMediaLinks: newLinks
        });
    }

    const removeLink = (i) => {
        const newLinks = [...formikCtx.current.values.socialMediaLinks];
        newLinks.splice(i, 1);
        formikCtx.current.setValues({
            ...formikCtx.current.values,
            socialMediaLinks: newLinks
        });
    }

    const handleRemoveImage = () => {
        setUserImage(null);
    }

    const handleAddSocialsClick = () => {
        setShowSocialMediaModal(true);
    };

    const handleCancel = () => {
        setShowSocialMediaModal(false);
    }

    return (
        <div className="d-flex flex-column w-60">
            <Formik
                innerRef={formikCtx}
                initialValues={getInitialValues()}
                validationSchema={CreatorProfileSchema}
                onSubmit={handleSubmitCoupon}
                enableReinitialize={true}
            >
                {({ handleChange, handleSubmit, values, errors, setFieldValue, touched }) => (
                    <div className="row gy-3">
                        <div className="">
                            <div className="card card-body gap-3 py-1">
                                <div className="d-flex justify-content-center my-3">
                                    {userImage ? (
                                        <div className="">
                                            <div>
                                                <Button
                                                    variant="dark"
                                                    className="position-absolute top-0 start-0 ms-4 my-2 rounded px-2 py-1"
                                                    onClick={removeImage}
                                                >
                                                    <IoTrash onClick={handleRemoveImage} size={16} color="white" />
                                                </Button>
                                                <div className="couponCarouselImage">
                                                    <img
                                                        src={getImageUrl()}
                                                        alt="carousel_img"
                                                        className="object-fit-cover rounded"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div onClick={handleImagePicker} className="d-flex flex-row gap-4 align-items-center w-100">
                                            <div className="creator_image_upload_container rounded br-50">
                                                <span color={theme.color}>
                                                    <BiSolidImageAdd size={28} className="me-2" />
                                                </span>
                                            </div>
                                            <b>Upload Image</b>
                                        </div>
                                    )}
                                    <input
                                        id="storeImageInput"
                                        type="file"
                                        ref={userImageInput}
                                        onChange={handleImageUpload}
                                        accept="image/*"
                                    />
                                </div>

                                <Form.Group>
                                    <Form.Label className="required">{t("Creator Name")}</Form.Label>
                                    <Form.Control
                                        value={values.creatorName}
                                        onChange={handleChange("creatorName")}
                                        type="text"
                                        placeholder={t("Enter Name")}
                                    />
                                    {touched.creatorName && errors.creatorName && <ErrorMsg msg={errors.creatorName} />}
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label className="required">{t("Your Description")}</Form.Label>
                                    <textarea
                                        value={values.creatorDescription}
                                        onChange={handleChange("creatorDescription")}
                                        placeholder={t("Enter Your Description")}
                                        className="form-control"
                                        id="exampleFormControlTextarea1"
                                        rows="3"
                                    ></textarea>
                                    {touched.creatorDescription && errors.creatorDescription && (
                                        <ErrorMsg msg={errors.creatorDescription} />
                                    )}
                                </Form.Group>
                                
                                <Button className="p-3 scheduled-message-btn align-items-center justify-content" onClick={handleAddSocialsClick}>
                                    <i class="fa fa-plus pe-3" style={{fontSize: "1.5em"}} aria-hidden="true"></i>
                                    <span>Add New Social Links</span>
                                </Button>

                                {buttonContent == null ? (
                                    <div className="d-flex mb-2 gap-2">
                                        {handleDeleteCoupon && (
                                            <Button
                                                w="full"
                                                onClick={handleDeleteCoupon}
                                                style={{ width: "100px" }}
                                                variant="danger"
                                                className="text-white"
                                                disabled={isDeleting || isSubmitting}
                                            >
                                                {isDeleting && (
                                                    <Spinner size="sm" className="me-2" animation="border" variant="white" />
                                                )}
                                                {t("Delete")}
                                            </Button>
                                        )}
                                        <Button
                                            className="action-btn orange-gradient-btn py-3"
                                            w="full"
                                            onClick={handleSubmitCoupon}
                                            style={{ width: "50%" }}
                                            disabled={isDeleting || isSubmitting}
                                        >
                                            {isSubmitting && (
                                                <Spinner size="sm" className="me-2" animation="border" variant="white" />
                                            )}
                                            {t("Update")}
                                        </Button>
                                        <Button className="transparent-orange-gradient-btn py-3" style={{width: "50%"}}>Cancel</Button>
                                    </div>
                                ) : (
                                    buttonContent(handleSubmit)
                                )}
                            </div>
                            <Modal
                                styles={customStyles}
                                modalIsOpen={modalIsOpen}
                                setIsOpen={setIsOpen}
                                body={<CreateService />}
                            />
                            <Modal
                                setIsOpen={setShowSocialMediaModal}
                                modalIsOpen={showSocialMediaModal}
                                showClose={false}
                                styles={customStyles}
                                body={
                                    <div className="d-flex flex-column gap-2 schedueledMessages-container p-2 pb-0">
                                        <Button className="scheduled-message-btn p-4" onClick={addLink}>
                                            <i class="fa fa-plus me-4" style={{color: "#ffffff", fontSize: "20px"}}></i>
                                            Add New Social Links
                                        </Button>
                                        <div className="d-flex flex-column gap-5">
                                            {values?.socialMediaLinks && Array.isArray(values.socialMediaLinks) && values.socialMediaLinks.map((linkObj, i) => (
                                                <div className="d-flex flex-column gap-2 mt-3 p-3 create-schedule-form schedule-messages-container" key={i}>
                                                    <Form.Label className="required">{t("Your Social Links")}</Form.Label>
                                                    <Form.Control
                                                        onChange={(e) => handleLinkChange(e.target.value, i, 'link')}
                                                        value={linkObj.link}
                                                        type="text"
                                                        placeholder={t("Enter Your Link...")}
                                                    />
                                                    <Form.Label className="required m-0 mt-3">Label</Form.Label>
                                                    <Form.Control
                                                        onChange={(e) => handleLinkChange(e.target.value, i, 'label')}
                                                        value={linkObj.label}
                                                        type="text"
                                                        placeholder={t("Enter Label...")}
                                                    />
                                                    <Button onClick={() => removeLink(i)} className="orange-gradient-btn mt-3">Delete</Button>
                                                </div>
                                            ))}

                                            {touched.socialMediaLinks && errors.socialMediaLinks && (
                                                <ErrorMsg msg={errors.socialMediaLinks} />
                                            )}
                                        </div>
                                        <div className="d-flex flex-row justify-content-center gap-3 py-3">
                                            <Button onClick={handleSubmitCoupon} className="orange-gradient-btn py-3" style={{width: "50%"}}>Save</Button>
                                            <Button onClick={handleCancel} className="transparent-orange-gradient-btn py-3" style={{width: "50%"}}>Cancel</Button>
                                        </div>
                                    </div>
                                }>
                            </Modal>
                        </div>
                        {/* <div className="col-6 col-md-8">
                            <h5>Creator Services</h5>
                            <div className="creator-services-container">
                                <ServiceCard />
                                <ServiceCard />
                                <ServiceCard />
                            </div>
                        </div> */}
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default CreatorProfileForm;