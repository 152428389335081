import axios from "axios";
import { config } from "../utils/config";
import { readCookie } from "../utils/cookie";
import { httpTokenInterceptor } from "./common";

class AptosAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + "/apt"
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    createAptosWallet(userId) {
        console.log("user create wallet ", userId);
        return this.axios.post(
            `/create_wallet/`,
            {
              userId,
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getWalletInfo(userId) {
      console.log("Fetching wallet info for user ", userId);
      return this.axios.get(`/get_wallet_info/${userId}`, {
        headers: {
          Authorization: `Bearer ${readCookie("token")}`,
        },
      });
    }

}

export const AptosAPIInstance = new AptosAPI();

export default AptosAPI;
