import React from "react";

export default function ExpressionsFormattedMessage({ message = "" }) {
    const parts = message.split(/(\*(.*?)\*)/);

if(!parts[1]) {return message;}
    const highLightedText = parts[1].replace(/\*/g,"");
    const renderedText = parts.map((part, index) => {
        if (index == 1) { //% 3 =
            return (
                <span style={{ color: "yellow" }} key={index}>
                    {part}
                </span>
            );
        } else if(highLightedText != part) {
            return part;
        }
    });

    return <div>{renderedText}</div>;
}
