/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import OrderItem from "./components/OrderItem";
import { orderAPIInstance } from "../../apis/OrderAPI";

function TakeOrder({ user }) {
    const path = useLocation();
    const orderId = path.pathname.split("/").at(-1);

    const [targetOrder, setTargetOrder] = useState(null);
    const [wallet, setWallet] = useState([]);

    const getOrderById = useCallback(async () => {
        const orderRes = await orderAPIInstance.getOrderById(orderId);
        if (orderRes.status === 200) {
            setTargetOrder(orderRes.data?.data?.order);
            parseBalances(orderRes.data?.data?.order);
        }
    }, []);

    const getExchangeUrl = () => {
        const navigateTo = new URL("/exchange", window.location);
        navigateTo.searchParams.set("pair", String(targetOrder?.pairKey).replace("/", "%2F"));
        navigateTo.searchParams.set("amount", String(targetOrder?.amount ?? 0));
        navigateTo.searchParams.set("price", String(targetOrder?.price ?? 0));
        navigateTo.searchParams.set("order", "market");
        navigateTo.searchParams.set("side", targetOrder?.side === "ask" ? "bid" : "ask");

        return navigateTo.pathname + navigateTo.search;
    };

    const parseBalances = (order) => {
        const points = order?.pairKey.split("/");

        if (points.length < 2) {
            return;
        }

        const tempWallet = [];

        user.pointWallet?.forEach((wallet) => {
            const symbol = wallet.pointTokenID.pointRewardSymbol;
            const name = wallet.pointTokenID.pointRewardName;

            if (symbol === points[0] || symbol === points[1]) {
                tempWallet.push({
                    name,
                    symbol,
                    amount: wallet.totalAmount
                });
            }
        });

        setWallet(tempWallet);
    };

    useEffect(() => {
        getOrderById();
    }, [getOrderById]);

    return (
        <Container className="px-lg-4 px-md-4">
            <OrderItem to="" order={targetOrder} />
            <div className="px-3 pt-3 py-1 border border-1 bg-white">
                <div className="mb-3" style={{ color: "#c5c6d0" }}>
                    Current Wallet Balance
                </div>
                {wallet.length > 0 ? (
                    wallet.map((item, i) => (
                        <div key={`wallet-${i}`} className="d-flex flex-row justify-content-between mb-2">
                            <span>{`${item.name} (${item.symbol})`}</span>
                            <span>{item.amount}</span>
                        </div>
                    ))
                ) : (
                    <div>No current wallet balance</div>
                )}
            </div>
            <Link to={getExchangeUrl()}>
                <Button className="w-100 mt-5">Take Order</Button>
            </Link>
        </Container>
    );
}

export default TakeOrder;
