import { Spinner } from "react-bootstrap";
import { formatDate } from "../../../utils/formatter/dateFormatter";
import DataTable from "react-data-table-component";

export default function PointsHistory({ loading, totalRows, sendPointsHistory, handlePerRowsChange, handlePageChange }) {
    const dataColumns = [
        {
            name: "USERNAME",
            cell: (row) => row?.receiverID?.username ?? "-",
            width: "15%"
        },
        {
            name: "MOBILE NUMBER",
            selector: (row) => row?.phoneNumber,
            width: "15%"
        },
        {
            name: "NUMBER OF POINTS",
            selector: (row) => row?.totalAmount,
            width: "20%"
        },
        {
            name: "NOTE",
            selector: (row) => (row?.note === "" ? "-" : row?.note),
            width: "35%"
        },
        {
            name: "DATE SENT",
            selector: (row) => formatDate(row?.createdAt),
            width: "15%"
        }
    ];

    return (
        <div className="">
            <div className="card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0 align-items-center">
                    <h6 className="mb-0 fw-bold">History</h6>
                </div>
                <div className="card-body">
                    <DataTable
                        columns={dataColumns}
                        data={sendPointsHistory}
                        defaultSortField={5}
                        selectableRows={false}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressComponent={
                            <div className="py-5">
                                <Spinner animation="border" variant="primary" />
                            </div>
                        }
                        fixedHeader
                        fixedHeaderScrollHeight="50vh"
                        responsive
                        highlightOnHover={true}
                    />
                </div>
            </div>
        </div>
    );
}
