import { FiChevronRight } from "react-icons/fi";
import Avatar from "../Comman/Avatar";
import { Card } from "react-bootstrap";

export default function ClickableListItem({ id, onClick, title, creatorName, itemUrl, onSubtitleClick }) {
    return (
        <li key={id} onClick={() => onClick(id)}>
            <Card className="p-3 mb-2 clickable-card clickable-list-item">
                <div className="d-flex align-items-center pointer">
                    <Avatar onClick={onClick} name={title} avatarUrl={itemUrl} className="xl pointer" />
                    <div className="flex-fill ms-3 text-truncate">
                        <h6 className="justify-content-between mb-0 d-flex clickable-list-text">{title}</h6>

                        <div className="d-flex align-items-center mt-2">
                            <small className="msg-time pointer clickable-list-text" onClick={onSubtitleClick}>
                                {creatorName}
                            </small>
                        </div>
                    </div>
                    <div>
                        <FiChevronRight size={24} className="clickable-list-text" />
                    </div>
                </div>
            </Card>
        </li>
    );
}
