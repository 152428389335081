import React from "react";
import OrderItem from "./OrderItem";

function TradeHistory({ orders }) {
    return !!orders ? (
        <div>
            {orders?.map((order, i) => (
                <OrderItem key={`trade-history-item-${i}`} to="" order={order} />
            ))}
        </div>
    ) : (
        <></>
    );
}

export default TradeHistory;
